// importation icons
import { IconType } from 'react-icons'
import { AiOutlineDashboard, AiOutlineTransaction } from 'react-icons/ai'
import { CiMobile3, CiMoneyCheck1 } from 'react-icons/ci'
import { MdOutlineAdminPanelSettings, MdOutlineSettings } from 'react-icons/md'
import { PiUsersThreeLight } from 'react-icons/pi'

type COMPONENT_TYPE = Array<{
    menu_name: string, menu_url: string, menu_icon: IconType,
    submenus?: Array<{ submenu_name: string, submenu_url: string }>
}>

export const menus: COMPONENT_TYPE = [
    {
        menu_name: 'Tableau de bord', menu_url: '/', menu_icon: AiOutlineDashboard,
    },
    {
        menu_name: 'Partenaire', menu_url: '/partner', menu_icon: PiUsersThreeLight,
    },
    {
        menu_name: 'Tarif', menu_url: '/tarif', menu_icon: CiMoneyCheck1,
    },
    {
        menu_name: 'Client', menu_url: '/customer', menu_icon: CiMobile3,
    },
    {
        menu_name: 'Transactions', menu_url: '/transaction-vitepay', menu_icon: AiOutlineTransaction,
        submenus: [
            { submenu_name: 'VITEPAY', submenu_url: '/transaction-vitepay' },
            { submenu_name: 'UBA', submenu_url: '/transaction-uba' },
        ]
    },
    {
        menu_name: 'Administrateur', menu_url: '/admin', menu_icon: MdOutlineAdminPanelSettings,
    },
    {
        menu_name: 'Réglage', menu_url: '/setting', menu_icon: MdOutlineSettings,
    },
]