import axios from 'axios'
import { ACTIVATED_ACCOUNT_CUSTOMER, CARD_LOSTED_CUSTOMER, DELETE_CUSTOMER, EDIT_ACTIVATED_ACCOUNT_CUSTOMER, ERROR_CUSTOMER, GET_ALL_CUSTOMERS, LOADING_CUSTOMER, admin, customer } from '../constants'
import { toast } from 'react-toastify'

const token = localStorage.getItem('token')

const _loadingCustomer = () => (dispatch: any) => {
    dispatch({ type: LOADING_CUSTOMER })
}

const _errorCustomer = (payload: any) => (dispatch: any) => {
    dispatch({ type: ERROR_CUSTOMER, payload })
}

export const _getAllCustomers = () => async (dispatch: any) => {
    try {
        dispatch(_loadingCustomer())

        const response = await axios.get(`${customer}`, { headers: { Authorization: `Bearer ${token}` } })

        dispatch({ type: GET_ALL_CUSTOMERS, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorCustomer(error?.response?.data))
    }
}

export const _activated_account_customer = (
    data: { id: string, cardNumber?: string, cardExpirationDate?: string, cardCVC?: string, cardAmount?: number },
    setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>,
    setActivateAccountData: React.Dispatch<React.SetStateAction<{ cardNumber: string, cardExpirationDate: string, cardCVC: string, cardAmount: string, }>>,
) => async (dispatch: any) => {
    try {
        dispatch(_loadingCustomer())

        const response = await axios.post(`${admin}/validate-customer`, data, { headers: { Authorization: `Bearer ${token}` } })

        toast.success('Le compte de l\'utilisateur a été activé avec succès.')

        setActivateAccountData && setActivateAccountData({ cardAmount: '', cardCVC: '', cardExpirationDate: '', cardNumber: '' })
        setSeeModalDisplayEditDelete(false)

        dispatch({ type: ACTIVATED_ACCOUNT_CUSTOMER, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorCustomer(error?.response?.data))
    }
}

export const _edit_activated_account_customer = (
    data: { id: string, cardNumber?: string, cardExpirationDate?: string, cardCVC?: string, cardAmount?: number },
    setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>,
) => async (dispatch: any) => {
    try {
        dispatch(_loadingCustomer())

        const response = await axios.put(`${customer}/${data.id}`, data, { headers: { Authorization: `Bearer ${token}` } })

        toast.success('Les informations ont été modifiées avec succès.')

        setSeeModalDisplayEditDelete(false)

        dispatch({ type: EDIT_ACTIVATED_ACCOUNT_CUSTOMER, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorCustomer(error?.response?.data))
    }
}

export const _deleteCustomer = (
    id: string,
    setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>,
) => async (dispatch: any) => {
    try {
        dispatch(_loadingCustomer())

        const response = await axios.delete(`${customer}/${id}`, { headers: { Authorization: `Bearer ${token}` } })

        toast.success('Le client a été supprimé avec succès.')

        setSeeModalDisplayEditDelete(false)

        dispatch({ type: DELETE_CUSTOMER, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorCustomer(error?.response?.data))
    }
}

export const _cardLosted = (
    id: string, data: { lostCard: boolean },
    setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>,
) => async (dispatch: any) => {
    try {
        dispatch(_loadingCustomer())

        const response = await axios.put(`${customer}/customerCardState/${id}`, data, { headers: { Authorization: `Bearer ${token}` } })

        setSeeModalDisplayEditDelete(false)

        dispatch({ type: CARD_LOSTED_CUSTOMER, payload: response.data.usr })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorCustomer(error?.response?.data))
    }
}