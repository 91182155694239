import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// my importations
import { SETTING_TYPE } from '../../utils/types'
import { ROOT_REDUCER_TYPE } from '../../redux/store'
import Modal from '../modal/Modal'
import { validation_setting } from '../../utils/validations'
import { _addSetting } from '../../redux/actions/setting.action'


type COMPONENT_TYPE = {
    openAddModal: boolean
    setOpenAddModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddSetting: FC<COMPONENT_TYPE> = (props) => {
    const { openAddModal, setOpenAddModal } = props

    const { loadingSetting } = useSelector((state: ROOT_REDUCER_TYPE) => state.setting)
    const dispatch = useDispatch<any>()

    const data: SETTING_TYPE = { name: '', value: '' }
    const [addData, setAddData] = useState(data)
    const [err, setErr] = useState<SETTING_TYPE>()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => setAddData({ ...addData, [e.target.id]: e.target.value })


    const handleSubmit = () => {
        const { error, initialError } = validation_setting(addData)

        if (error.name !== initialError.name || error.value !== initialError.value) {
            setErr(error)
        } else {
            setErr(initialError)

            const { name, value } = addData

            dispatch(_addSetting({ name: name.trim(), value: value.trim() }, setAddData))
        }
    }

    return (
        !openAddModal ? <></> :
            <Modal title='Ajout réglage' loading={loadingSetting} setOpenAddModal={setOpenAddModal} show_modal_bottom handleSubmit={handleSubmit} error_data={data} setErrorData={setErr}>
                <div className='modal_content'>
                    <div className='add_edit_input_label_container'>
                        <label htmlFor='name'>Nom</label>
                        <input type='text' name='name' id='name' value={addData.name} disabled={loadingSetting} placeholder={'Nom'} onChange={handleChange} />
                        {err?.name && <span className='error'>{err?.name}</span>}
                    </div>

                    <div className='add_edit_input_label_container'>
                        <label htmlFor='value'>Valeur</label>
                        <input type='text' name='value' id='value' value={addData.value} disabled={loadingSetting} placeholder={'Valeur'} onChange={handleChange} />
                        {err?.value && <span className='error'>{err?.value}</span>}
                    </div>
                </div>
            </Modal>
    )
}

export default AddSetting