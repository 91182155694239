import { INITIAL_SETTING_STATE_TYPE } from '../../utils/types'
import { ADD_SETTING, DELETE_SETTING, EDIT_SETTING, ERROR_SETTING, GET_ALL_SETTINGS, LOADING_SETTING } from '../constants'

const initialState: INITIAL_SETTING_STATE_TYPE = {
    setting: null,
    allSettings: [],
    loadingSetting: false,
    error: null
}

const settingReducer = (state = initialState, action: { type: string, payload: any }): INITIAL_SETTING_STATE_TYPE => {
    const { type, payload } = action

    switch (type) {
        case LOADING_SETTING:
            return { ...state, loadingSetting: true }

        case ERROR_SETTING:
            return { ...state, error: payload, loadingSetting: false }

        case GET_ALL_SETTINGS:
            return { ...state, allSettings: payload, loadingSetting: false, error: null }

        case ADD_SETTING:
            return { ...state, allSettings: [payload, ...state.allSettings], loadingSetting: false, error: null }

        case EDIT_SETTING:
            return {
                ...state,
                allSettings: state.allSettings.map(tarif => {
                    if (tarif.id === payload.id) {
                        return payload
                    } else return tarif
                }),
                loadingSetting: false, error: null
            }

        case DELETE_SETTING:
            return {
                ...state,
                allSettings: state.allSettings.filter(tarif => tarif.id !== payload.id),
                loadingSetting: false, error: null
            }

        default:
            return state
    }
}

export default settingReducer