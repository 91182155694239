import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// my importations
import Modal from '../../modal/Modal'
import Divider from '../../divider/Divider'
import { api_img } from '../../../redux/constants'
import { ROOT_REDUCER_TYPE } from '../../../redux/store'
import { displayDate, downloadImage, } from '../../../utils/functions'
import { validation_customer_activate_account } from '../../../utils/validations'
import { CUSTOMER_ACTIVATE_ACCOUNT_TYPE, CUSTOMER_TYPE } from '../../../utils/types'
import { _activated_account_customer, _cardLosted, _deleteCustomer, _edit_activated_account_customer } from '../../../redux/actions/customer.action'
// my icons
import { TfiPrinter } from 'react-icons/tfi'
import { MdOutlineFileDownload } from 'react-icons/md'

type COMPONENT_TYPE = {
    type: string
    row: CUSTOMER_TYPE
    seeModalDisplayEditDelete: boolean
    setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomerModal: FC<COMPONENT_TYPE> = (props) => {
    const { row, seeModalDisplayEditDelete, setSeeModalDisplayEditDelete, type } = props

    const cardData: CUSTOMER_ACTIVATE_ACCOUNT_TYPE = { cardNumber: '', cardExpirationDate: '', cardCVC: '', cardAmount: '', }

    const { loadingCustomer, allCustomers } = useSelector((state: ROOT_REDUCER_TYPE) => state.customer)
    const dispatch = useDispatch<any>()

    const [activateAccountData, setActivateAccountData] = useState(cardData)
    const [editActivateAccountData, setEditActivateAccountData] = useState(cardData)
    const [err, setErr] = useState<CUSTOMER_ACTIVATE_ACCOUNT_TYPE>()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'add' | 'edit' = 'add') => {
        if (type === 'add') setActivateAccountData({ ...activateAccountData, [e.target.id]: e.target.value })
        else if (type === 'edit') setEditActivateAccountData({ ...editActivateAccountData, [e.target.id]: e.target.value })
    }

    const handleSubmit = () => {
        if (type === 'activer') {
            const { error, initialError } = validation_customer_activate_account(activateAccountData)

            if (error.cardAmount !== initialError.cardAmount || error.cardCVC !== initialError.cardCVC || error.cardExpirationDate !== initialError.cardExpirationDate || error.cardNumber !== initialError.cardNumber) {
                setErr(error)
            } else {
                setErr(initialError)

                dispatch(_activated_account_customer(
                    { id: row?.id as string, ...activateAccountData, cardAmount: parseInt(activateAccountData.cardAmount, 10), },
                    setSeeModalDisplayEditDelete, setActivateAccountData
                ))
            }
        } else if (type === 'modifier') {
            const { error, initialError } = validation_customer_activate_account(editActivateAccountData)

            if (error.cardAmount !== initialError.cardAmount || error.cardCVC !== initialError.cardCVC || error.cardExpirationDate !== initialError.cardExpirationDate || error.cardNumber !== initialError.cardNumber) {
                setErr(error)
            } else {
                setErr(initialError)

                dispatch(_edit_activated_account_customer(
                    { id: row?.id as string, ...editActivateAccountData, cardAmount: parseInt(editActivateAccountData.cardAmount, 10), },
                    setSeeModalDisplayEditDelete
                ))
            }
        } else if (type === 'supprimer') {
            dispatch(_deleteCustomer(
                row?.id as string, setSeeModalDisplayEditDelete
            ))
        } else if (type === 'carte_perdue') {
            dispatch(_cardLosted(
                row?.id as string, { lostCard: false },
                setSeeModalDisplayEditDelete
            ))
        }
    }

    const handleDownload = () => {
        const pdfDiv = document.getElementById('pdf') as HTMLDivElement

        if (pdfDiv) {
            const printContent = document.createElement('div')
            printContent.innerHTML = pdfDiv.innerHTML

            // Création de la div de chargement
            const loadingDiv = document.createElement('div')
            loadingDiv.style.cssText = `
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.8);
                color: white;
                display: none;
                align-items: center;
                justify-content: center;
                z-index: 9999; /* Assurez-vous que le z-index est supérieur à celui du contenu */
            `
            loadingDiv.innerHTML = `<span style="text-align: center;">Veuillez patienter pendant le chargement des <br /> données avant que l'impression ne se lance. <br /> ...... </span>`

            const printWindow = window.open('', '_blank')

            if (printWindow) {
                printWindow.document.open()
                printWindow.document.write('<html><head><title>IMPRESSION | CLIENT</title>')
                printWindow.document.write('<style>')
                printWindow.document.write(`
                    .display_information_container {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                    }
                    
                    .display_information_container .display_information {
                        width: 49%;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 10px;
                    }
                    
                    .display_information_container .display_information .title {
                        display: inline-block;
                        font-size: 0.6875rem;
                        margin-bottom: 5px;
                        color: #3C4B64;
                        font-weight: 600;
                        text-transform: uppercase;
                        width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    
                    .display_information_container .display_information .title.global {
                        font-size: 0.875rem;
                        text-decoration: underline;
                        color: #1877F2;
                    }
                    
                    .display_information_container .display_information .value {
                        width: 100%;
                        font-size: 0.6875rem;
                        text-align: justify;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    
                    .display_information_container .display_information .value.list {
                        white-space: initial;
                        text-overflow: initial;
                        overflow: initial;
                    }
                    
                    .display_information_container .display_information .img_container {
                        height: 140px;
                        width: 100%;
                        border: 1px solid rgba(141, 148, 158, 0.2);
                        border-radius: 5px;
                        padding: 1px;
                    }
                    
                    .display_information_container .display_information .img_container img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 5px;
                    }
                    
                    .display_information_container .display_information.w_100 {
                        width: 100%;
                    }
                    
                    .display_information_container .display_information.w_100 .img_container {
                        height: 140px;
                        width: 100%;
                    }
                    
                    .display_information_container .display_information.w_100 .img_container img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        border-radius: 5px;
                    }
                    
                    .display_information_container .display_information .download_icon {
                        display: none;
                    }

                    .divider {
                        height: 1px;
                        width: 100%;
                        background-color: rgba(141, 148, 158, 0.2);
                    }
                    
                    @media print {
                        @page {
                            margin: 30px;
                        }
                    
                        body {
                            margin: 0 1.6cm;
                        }
                    }
                `)
                printWindow.document.write('</style>')
                printWindow.document.write('</head><body>')

                // Ajout de la div de chargement à la fenêtre d'impression
                printWindow.document.body.appendChild(loadingDiv)

                // Afficher la div de chargement pendant le chargement du contenu
                loadingDiv.style.display = 'flex' // ou 'block' pour afficher la div

                // Création de la div englobant le contenu à imprimer
                printWindow.document.write('<div style="position: relative">')
                printWindow.document.write(printContent.innerHTML)
                printWindow.document.write('</div>')

                printWindow.document.write('</body></html>')
                printWindow.document.close()

                printWindow.onload = () => {
                    // Cacher la div de chargement une fois que le contenu est chargé
                    loadingDiv.style.display = 'none'

                    // Imprimer le contenu de la div
                    printWindow.print()
                    printWindow.close()
                }
            } else {
                alert('Le popup de l\'impression a été bloqué. Veuillez autoriser les popups pour cette page.')
            }
        } else {
            alert(`La balise div avec l'ID PDF n'a pas été trouvée.`)
        }
    }

    useEffect(() => {
        row?.status && setEditActivateAccountData({ id: row ? row.id : '', cardAmount: row ? row.cardAmount.toString() : '', cardCVC: row ? row.cardCVC : '', cardExpirationDate: row ? row.cardExpirationDate : '', cardNumber: row ? row.cardNumber : '', })
    }, [row])

    return (
        !seeModalDisplayEditDelete ? <></> :
            <>
                {type === 'afficher' &&
                    <Modal title={'Information du client'} setOpenAddModal={setSeeModalDisplayEditDelete} width={700}>
                        <>
                            <div className='download_btn_container'>
                                <button className='download_btn' onClick={handleDownload}>
                                    <TfiPrinter className='download_btn_icon' size={18} />
                                    <span className='download_btn_name'>Imprimer</span>
                                </button>
                            </div>
                            <div className='modal_content' style={{ padding: '0 10px 10px 10px' }} id='pdf'>
                                {/* Info pièce d'identité */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Information sur les pièces d'identité</span>
                                    </div>
                                    <div className='display_information'>
                                        <span className='title'>Photo de profil</span>
                                        <div className='img_container' style={{ width: 140, transform: `rotate(90deg)`, }}>
                                            <img src={`${api_img}/${row?.photo}`} alt='Photo_de_profil' />
                                        </div>
                                        <MdOutlineFileDownload className='download_icon' title='Télécharger' onClick={() => downloadImage(`${api_img}/${row?.photo}`, 'profil')} />
                                    </div>
                                    <div className='display_information'>
                                        <span className='title'>Pièce d'identité {'-->'} Type de pièce: {row?.documentType || 'N/A'}</span>
                                        <div className='img_container'>
                                            <img src={`${api_img}/${row?.document}`} style={{ objectFit: 'contain', }} alt='Photo_de_piece_identite' />
                                        </div>
                                        <MdOutlineFileDownload className='download_icon' title='Télécharger' onClick={() => downloadImage(`${api_img}/${row?.document}`, 'identite')} />
                                    </div>
                                    <div className='display_information'>
                                        <span className='title'>Numéro de la pièce</span>
                                        <span className='value'>{row?.documentNumber ? row.documentNumber : 'N/A'}</span>
                                    </div>
                                    <div className='display_information'>
                                        <span className='title'>Autorité de délivrance de la pièce</span>
                                        <span className='value'>{row?.documentLicensingAuthority ? row.documentLicensingAuthority : 'N/A'}</span>
                                    </div>
                                    <div className='display_information'>
                                        <span className='title'>Date de délivrance de la pièce</span>
                                        <span className='value'>{row?.documentDeliveryDate ? displayDate((new Date(row?.documentDeliveryDate)).getTime()) : 'N/A'}</span>
                                    </div>
                                    <div className='display_information'>
                                        <span className='title'>Date d'expiration de la pièce</span>
                                        <span className='value'>{row?.documentExpirationDate ? displayDate((new Date(row?.documentExpirationDate)).getTime()) : 'N/A'}</span>
                                    </div>
                                </div>

                                <Divider marginVertical='0' />

                                {/* Info compte */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Information sur le compte</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Compte UBA</span>
                                        <span className='value'>{row?.accountUBA ? row.accountUBA : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>État du compte</span>
                                        <span className='value'>{row?.status ? 'Activé' : 'Non activé'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Montant à échanger</span>
                                        <span className='value'>{row?.AmountToExchange ? row.AmountToExchange : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Montant total sur la carte</span>
                                        <span className='value'>{row?.cardAmount ? `${row.cardAmount} CFA` : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Carte perdue ?</span>
                                        <span className='value'>{row?.lostCard ? 'Oui' : 'Non'}</span>
                                    </div>
                                </div>

                                <Divider marginVertical='0' />

                                {/* Info user */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Information sur le client</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Nom</span>
                                        <span className='value'>{row?.name}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Prénom</span>
                                        <span className='value'>{row?.firstname}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Email</span>
                                        <span className='value'>{row?.email}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Téléphone</span>
                                        <span className='value'>{row?.phone}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Pays de résidence</span>
                                        <span className='value'>{row?.residenceCountry ? row.residenceCountry : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Ville</span>
                                        <span className='value'>{row?.city ? row.city : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Nationnalité</span>
                                        <span className='value'>{row?.nationality ? row.nationality : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Lieu de naissance</span>
                                        <span className='value'>{row?.placeOfBirth ? row.placeOfBirth : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Domaine d'activité</span>
                                        <span className='value'>{row?.fieldOfActivity ? row.fieldOfActivity : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Activité en cours</span>
                                        <span className='value'>{row?.currentActivity ? row.currentActivity : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Adresse</span>
                                        <span className='value'>{row?.address}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Date de naissance</span>
                                        <span className='value'>{displayDate((new Date(row?.birthday)).getTime())}</span>
                                    </div>
                                </div>

                                <Divider marginVertical='0' />

                                {/* Card information */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Information de la carte pour activer le compte</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Numéro de la carte</span>
                                        <span className='value'>{row?.cardNumber ? row.cardNumber : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Nom sur la carte</span>
                                        <span className='value'>{row?.nameOnCard ? row.nameOnCard : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>CVC</span>
                                        <span className='value'>{row?.cardCVC ? row.cardCVC : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Date d'expiration de la carte</span>
                                        <span className='value'>{row?.cardExpirationDate ? row.cardExpirationDate : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Montant initial de la carte</span>
                                        <span className='value'>{row?.cardAmount ? `${row.cardAmount} CFA` : 'N/A'}</span>
                                    </div>
                                </div>

                                <Divider marginVertical='0' />

                                {/* Information emergency */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Information du contact en cas d'urgence</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Nom</span>
                                        <span className='value'>{row?.contactName ? row.contactName : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Prénom</span>
                                        <span className='value'>{row?.contactFirstname ? row.contactFirstname : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Numéro de téléphone</span>
                                        <span className='value'>{row?.contactPhone ? row.contactPhone : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Email</span>
                                        <span className='value'>{row?.contactEmail ? row.contactEmail : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Adresse</span>
                                        <span className='value'>{row?.contactAddress ? row.contactAddress : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Nature de relation</span>
                                        <span className='value'>{row?.contactRelationship ? row.contactRelationship : 'N/A'}</span>
                                    </div>
                                </div>

                                <Divider marginVertical='0' />

                                {/* parrainage */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Information sur le parrainage</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Code de parrainage</span>
                                        <span className='value'>{row?.parrainCode ? row.parrainCode : 'N/A'}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Parrainé par</span>
                                        <span className='value'>
                                            {row?.parrain ?
                                                allCustomers?.map(customer => {
                                                    if (customer?.id === row.parrain) return `${customer?.name} ${customer?.firstname}`
                                                    else return null
                                                }) : 'N/A'
                                            }
                                        </span>
                                    </div>

                                    <div className='display_information w_100'>
                                        <span className='title'>Parrain de</span>
                                        <span className='value list'>
                                            {row?.sponsored?.length !== 0 ?
                                                row?.sponsored?.map(user_id => {
                                                    return allCustomers?.map(customer => {
                                                        if (user_id === customer.id) return `${customer?.name} ${customer?.firstname}, `
                                                        return null
                                                    })
                                                })
                                                : 'N/A'
                                            }
                                        </span>
                                    </div>
                                </div>

                                <Divider marginVertical='0' />

                                {/* Signature */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Signature du client</span>
                                        <div className='img_container'>
                                            <img src={`${api_img}/${row?.signature}`} alt='Signature_du_client' />
                                        </div>
                                        <MdOutlineFileDownload className='download_icon' title='Télécharger' onClick={() => downloadImage(`${api_img}/${row?.signature}`, 'signature')} />
                                    </div>
                                </div>

                                <Divider marginVertical='0' />

                                {/* CreatedAt and UpdatedAt */}
                                <div className='display_information_container' style={{ marginTop: 10, }}>
                                    <div className='display_information w_100'>
                                        <span className='title global'>Information sur les dates</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Date de création</span>
                                        <span className='value'>{displayDate((new Date(row?.createdAt)).getTime(), true)}</span>
                                    </div>

                                    <div className='display_information'>
                                        <span className='title'>Date de dernière modification</span>
                                        <span className='value'>{displayDate((new Date(row?.updatedAt)).getTime(), true)}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Modal>
                }

                {type === 'modifier' &&
                    <Modal title='Modification du compte du client' loading={loadingCustomer} setOpenAddModal={setSeeModalDisplayEditDelete} show_modal_bottom handleSubmit={handleSubmit}>
                        <div className='modal_content'>
                            <div className='edit_activate_container'>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardNumber'>Numéro de la carte</label>
                                    <input type='text' name='cardNumber' id='cardNumber' maxLength={16} value={editActivateAccountData.cardNumber} disabled={loadingCustomer} placeholder={`Ex: 1111222233334444`} onChange={e => handleChange(e, 'edit')} />
                                    {err?.cardNumber && <span className='error'>{err?.cardNumber}</span>}
                                </div>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardExpirationDate'>Date d'expiration de la carte</label>
                                    <input type='text' name='cardExpirationDate' id='cardExpirationDate' maxLength={5} value={editActivateAccountData.cardExpirationDate} disabled={loadingCustomer} placeholder={`Ex: mm/AA -> 01/28`} onChange={e => handleChange(e, 'edit')} />
                                    {err?.cardExpirationDate && <span className='error'>{err?.cardExpirationDate}</span>}
                                </div>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardCVC'>Numéro CVC</label>
                                    <input type='number' name='cardCVC' id='cardCVC' value={editActivateAccountData.cardCVC} disabled={loadingCustomer} placeholder={`Ex: 123`} onChange={e => handleChange(e, 'edit')} />
                                    {err?.cardCVC && <span className='error'>{err?.cardCVC}</span>}
                                </div>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardAmount'>Montant initial de la carte (CFA)</label>
                                    <input type='number' name='cardAmount' id='cardAmount' value={editActivateAccountData.cardAmount} disabled={loadingCustomer} placeholder={`Montant initial de la carte`} onChange={e => handleChange(e, 'edit')} />
                                    {err?.cardAmount && <span className='error'>{err?.cardAmount}</span>}
                                </div>
                            </div>
                        </div>
                    </Modal>
                }

                {type === 'supprimer' &&
                    <Modal title='Suppression du client' loading={loadingCustomer} setOpenAddModal={setSeeModalDisplayEditDelete} show_modal_bottom handleSubmit={handleSubmit} close_btn_name='NON' send_btn_name='OUI'>
                        <div className='modal_content'>
                            <div className='delete_container'>
                                <p className='ask'>
                                    Voulez-vous vraiment supprimer <br /> <span className='name'>{row?.name} {row?.firstname}</span> ?
                                </p>
                            </div>
                        </div>
                    </Modal>
                }

                {type === 'activer' &&
                    <Modal title='Activation du compte du client' loading={loadingCustomer} setOpenAddModal={setSeeModalDisplayEditDelete} show_modal_bottom handleSubmit={handleSubmit} send_btn_name='Activer'>
                        <div className='modal_content'>
                            <div className='activate_container'>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardNumber'>Numéro de la carte</label>
                                    <input type='text' name='cardNumber' id='cardNumber' maxLength={16} value={activateAccountData.cardNumber} disabled={loadingCustomer} placeholder={`Ex: 1111222233334444`} onChange={handleChange} />
                                    {err?.cardNumber && <span className='error'>{err?.cardNumber}</span>}
                                </div>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardExpirationDate'>Date d'expiration de la carte</label>
                                    <input type='text' name='cardExpirationDate' id='cardExpirationDate' maxLength={5} value={activateAccountData.cardExpirationDate} disabled={loadingCustomer} placeholder={`Ex: mm/AA -> 01/28`} onChange={handleChange} />
                                    {err?.cardExpirationDate && <span className='error'>{err?.cardExpirationDate}</span>}
                                </div>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardCVC'>Numéro CVC</label>
                                    <input type='number' name='cardCVC' id='cardCVC' value={activateAccountData.cardCVC} disabled={loadingCustomer} placeholder={`Ex: 123`} onChange={handleChange} />
                                    {err?.cardCVC && <span className='error'>{err?.cardCVC}</span>}
                                </div>
                                <div className='_input_label_container'>
                                    <label htmlFor='cardAmount'>Montant initial de la carte (CFA)</label>
                                    <input type='number' name='cardAmount' id='cardAmount' value={activateAccountData.cardAmount} disabled={loadingCustomer} placeholder={`Montant initial de la carte`} onChange={handleChange} />
                                    {err?.cardAmount && <span className='error'>{err?.cardAmount}</span>}
                                </div>
                            </div>
                        </div>
                    </Modal>
                }

                {type === 'carte_perdue' &&
                    <Modal title='Gestion de la carte perdue' loading={loadingCustomer} setOpenAddModal={setSeeModalDisplayEditDelete} show_modal_bottom handleSubmit={handleSubmit}>
                        <div className='modal_content'>
                            <p className='lost_card_container'>
                                Êtes-vous sûr de vouloir valider que la perte de la carte de <br /> <span>{`${row.name} ${row.firstname}`}</span> <br /> a été résolue ?
                            </p>
                        </div>
                    </Modal>
                }
            </>
    )
}

export default CustomerModal