import axios from 'axios'
import { toast } from 'react-toastify'
// my importations
import { ERROR_TRANSACTION, GET_ALL_TRANSACTIONS, LOADING_TRANSACTION, transaction, } from '../constants'

const token = localStorage.getItem('token')

const _loadingTransaction = () => (dispatch: any) => {
    dispatch({ type: LOADING_TRANSACTION })
}

const _errorTransaction = (payload: any) => (dispatch: any) => {
    dispatch({ type: ERROR_TRANSACTION, payload })
}

export const _getAllTransactions = (type: 'vitepay' | 'uba') => async (dispatch: any) => {
    try {
        dispatch(_loadingTransaction())

        const response = await axios.get(`${transaction}/${type}`, { headers: { Authorization: `Bearer ${token}` } })

        dispatch({ type: GET_ALL_TRANSACTIONS, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorTransaction(error?.response?.data))
    }
}