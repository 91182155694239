import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// my importations
import PageContainer from '../components/page_container/PageContainer'
import { page_transaction_vitepay } from '../utils/page_name'
import TitleLink from '../components/title_link/TitleLink'
import { _getAllTransactions } from '../redux/actions/transaction.action'
import ListeTransaction from '../components/listes/ListeTransaction'

const TransactionVItepay = () => {

    const [openAddModal, setOpenAddModal] = useState(false)
    console.log(openAddModal)

    const dispatch = useDispatch<any>()

    useEffect(() => {
        dispatch(_getAllTransactions('vitepay'))
    }, [dispatch])

    return (
        <PageContainer title_page={page_transaction_vitepay}>
            <div className='right_part_container'>
                <TitleLink title='Transactions vitepay' links={['Transaction', 'vitepay']} />

                <ListeTransaction title='transaction' setOpenAddModal={setOpenAddModal} />
            </div>
        </PageContainer>
    )
}

export default TransactionVItepay