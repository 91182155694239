import React, { FC, useEffect, useState } from 'react'
import { SETTING_TYPE } from '../../../utils/types'
import Modal from '../../modal/Modal'
import { ROOT_REDUCER_TYPE } from '../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { validation_setting, } from '../../../utils/validations'
import Divider from '../../divider/Divider'
import { displayDate } from '../../../utils/functions'
import { _deleteSetting, _editSetting } from '../../../redux/actions/setting.action'

type COMPONENT_TYPE = {
    type: string
    row: SETTING_TYPE
    seeModalDisplayEditDelete: boolean
    setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>
}

const SettingModal: FC<COMPONENT_TYPE> = (props) => {
    const { row, seeModalDisplayEditDelete, setSeeModalDisplayEditDelete, type } = props

    const data: SETTING_TYPE = { id: '', name: '', value: '' }

    const { loadingSetting } = useSelector((state: ROOT_REDUCER_TYPE) => state.setting)
    const dispatch = useDispatch<any>()

    const [editData, setEditData] = useState(data)
    const [err, setErr] = useState<SETTING_TYPE>()

    const handleSubmit = () => {
        if (type === 'modifier') {
            const { error, initialError } = validation_setting(editData)

            if (error.name !== initialError.name || error.value !== initialError.value) {
                setErr(error)
            } else {
                setErr(initialError)

                const { id, name, value } = editData

                id && dispatch(_editSetting(id, { name: name.trim(), value: value.trim() }, setSeeModalDisplayEditDelete))
            }
        } else if (type === 'supprimer') {
            row?.id && dispatch(_deleteSetting(row.id as string, setSeeModalDisplayEditDelete))
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => setEditData({ ...editData, [e.target.id]: e.target.value })

    useEffect(() => {
        setEditData({ id: row ? row.id : '', name: row ? row.name : '', value: row ? row.value : '' })
    }, [row])

    return (
        !seeModalDisplayEditDelete ? <></> :
            <>
                {type === 'afficher' &&
                    <Modal title='Information du réglage' setOpenAddModal={setSeeModalDisplayEditDelete} width={700}>
                        <div className='modal_content'>
                            <div className='display_information_container'>
                                <div className='display_information'>
                                    <span className='title'>Nom</span>
                                    <span className='value'>{row?.name}</span>
                                </div>

                                <div className='display_information'>
                                    <span className='title'>Valeur</span>
                                    <span className='value'>{row?.value}</span>
                                </div>
                            </div>

                            <Divider marginVertical='0' />

                            <div className='display_information_container' style={{ marginTop: 10, }}>
                                <div className='display_information'>
                                    <span className='title'>Date de création</span>
                                    <span className='value'>{displayDate((new Date(row?.createdAt as Date)).getTime(), true)}</span>
                                </div>

                                <div className='display_information'>
                                    <span className='title'>Date de dernière modification</span>
                                    <span className='value'>{displayDate((new Date(row?.updatedAt as Date)).getTime(), true)}</span>
                                </div>
                            </div>
                        </div>
                    </Modal>
                }

                {type === 'modifier' &&
                    <Modal title='Modification du partenaire' loading={loadingSetting} setOpenAddModal={setSeeModalDisplayEditDelete} show_modal_bottom handleSubmit={handleSubmit} close_btn_name='Annuler' error_data={data} setErrorData={setErr}>
                        <div className='modal_content'>
                            <div className='add_edit_input_label_container'>
                                <label htmlFor='name'>Nom</label>
                                <input type='text' name='name' id='name' value={editData.name} disabled={loadingSetting} placeholder='Tarif' onChange={handleChange} />
                                {err?.name && <span className='error'>{err?.name}</span>}
                            </div>

                            <div className='add_edit_input_label_container'>
                                <label htmlFor='value'>Valeur</label>
                                <input type='text' name='value' id='value' value={editData.value} disabled={loadingSetting} placeholder='Tarif' onChange={handleChange} />
                                {err?.value && <span className='error'>{err?.value}</span>}
                            </div>
                        </div>
                    </Modal>
                }

                {type === 'supprimer' &&
                    <Modal title='Suppression du réglage' loading={loadingSetting} setOpenAddModal={setSeeModalDisplayEditDelete} show_modal_bottom handleSubmit={handleSubmit} close_btn_name='NON' send_btn_name='OUI'>
                        <div className='modal_content'>
                            <p className='delete_container'>
                                Voulez-vous vraiment supprimer <br /> <span className='name'>{row?.name}</span> ?
                            </p>
                        </div>
                    </Modal>
                }
            </>
    )
}

export default SettingModal