import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// my importations
import PageContainer from '../components/page_container/PageContainer'
import { page_setting } from '../utils/page_name'
import TitleLink from '../components/title_link/TitleLink'
import { _getAllSettings } from '../redux/actions/setting.action'
import AddSetting from '../components/add/AddSetting'
import ListeSetting from '../components/listes/ListeSetting'

const Setting = () => {

    const [openAddModal, setOpenAddModal] = useState(false)

    const dispatch = useDispatch<any>()

    useEffect(() => {
        dispatch(_getAllSettings())
    }, [dispatch])

    return (
        <PageContainer title_page={page_setting}>
            <div className='right_part_container'>
                <TitleLink title='Réglage' links={['Réglage']} />

                <AddSetting openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
                <ListeSetting title='setting' setOpenAddModal={setOpenAddModal} />
            </div>
        </PageContainer>
    )
}

export default Setting