import { INITIAL_TRANSACTION_STATE_TYPE } from '../../utils/types'
import { ERROR_TRANSACTION, GET_ALL_TRANSACTIONS, LOADING_TRANSACTION } from '../constants'

const initialState: INITIAL_TRANSACTION_STATE_TYPE = {
    transaction: null,
    allTransactions: [],
    loadingTransaction: false,
    error: null
}

const transactionReducer = (state = initialState, action: { type: string, payload: any }): INITIAL_TRANSACTION_STATE_TYPE => {
    const { type, payload } = action

    switch (type) {
        case LOADING_TRANSACTION:
            return { ...state, loadingTransaction: true, allTransactions: [], }

        case ERROR_TRANSACTION:
            return { ...state, error: payload, loadingTransaction: false, allTransactions: [] }

        case GET_ALL_TRANSACTIONS:
            return { ...state, allTransactions: payload, loadingTransaction: false, error: null }

        default: return state
    }
}

export default transactionReducer