export const site_name = 'EMPAY'
export const page_connexion = `${site_name} | Connexion`
export const page_dashboard = `${site_name} | Tableau de bord`
export const page_forget_password = `${site_name} | Mot de passe oublié`

export const page_admin = `${site_name} | Administrateurs`
export const page_customer = `${site_name} | Clients`
export const page_partner = `${site_name} | Partenaires`
export const page_tarif = `${site_name} | Tarifs`
export const page_transaction_vitepay = `${site_name} | Transactions Vitepay`
export const page_transaction_uba = `${site_name} | Transactions UBA`
export const page_setting = `${site_name} | Réglages`


