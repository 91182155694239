import React, { FC, } from 'react'
// my importations
import { TRANSACTION_TYPE } from '../../../utils/types'
import Modal from '../../modal/Modal'
import { displayDate } from '../../../utils/functions'
import Divider from '../../divider/Divider'

type COMPONENT_TYPE = {
    type: string
    row: TRANSACTION_TYPE
    seeModalDisplayEditDelete: boolean
    setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>
}

const TransactionModal: FC<COMPONENT_TYPE> = (props) => {
    const { row, seeModalDisplayEditDelete, setSeeModalDisplayEditDelete, type } = props

    return (
        !seeModalDisplayEditDelete ? <></> :
            <>
                {type === 'afficher' &&
                    <Modal title={'Information de la transaction'} setOpenAddModal={setSeeModalDisplayEditDelete}>
                        <div className='modal_content'>
                            <div className='display_information_container'>
                                <div className='display_information'>
                                    <span className='title'>Client ID</span>
                                    <span className='value'>{row?.customerId}</span>
                                </div>
                                <div className='display_information'>
                                    <span className='title'>Numéro de téléphone</span>
                                    <span className='value'>{row?.phone ? row.phone : 'N/A'}</span>
                                </div>
                                <div className='display_information'>
                                    <span className='title'>Montant</span>
                                    <span className='value'>{row?.amount} FCFA</span>
                                </div>
                                <div className='display_information'>
                                    <span className='title'>Etat du paiement</span>
                                    <span className='value'>{row?.paymentStatus === 'PENDING' ? 'En cours' : row?.paymentStatus === 'CANCELED' ? 'Annulé' : 'Validé'}</span>
                                </div>
                                <div className='display_information w_100'>
                                    <span className='title'>Type de transaction</span>
                                    <span className='value'>{row?.transactionType === 'crediter' ? 'Créditer' : 'Débiter'}</span>
                                </div>
                                <div className='display_information w_100'>
                                    <span className='title'>Message</span>
                                    <span className='value list'>{row?.message ? row.message : 'N/A'}</span>
                                </div>
                            </div>

                            <Divider marginVertical='0' />

                            <div className='display_information_container' style={{ marginTop: 10, }}>
                                <div className='display_information'>
                                    <span className='title'>Date de création</span>
                                    <span className='value'>{displayDate((new Date(row?.createdAt)).getTime(), true)}</span>
                                </div>

                                <div className='display_information'>
                                    <span className='title'>Date de dernière modification</span>
                                    <span className='value'>{displayDate((new Date(row?.updatedAt)).getTime(), true)}</span>
                                </div>
                            </div>
                        </div>
                    </Modal>
                }
            </>
    )
}

export default TransactionModal