import React, { FC, useState } from 'react'
import Popup from 'reactjs-popup'
import { useSelector } from 'react-redux'
// my importations
import ListeContainer from '../liste/ListeContainer'
import Liste from '../liste/Liste'
import { TRANSACTION_TYPE } from '../../utils/types'
import { ROOT_REDUCER_TYPE } from '../../redux/store'
import TransactionModal from '../liste/modal_display_edit_delete/TransactionModal'
import { displayDate } from '../../utils/functions'
// importation icons
import { AiOutlineEye } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'

type COMPONENT_TYPE = {
    title: string
    setOpenAddModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ListeTransaction: FC<COMPONENT_TYPE> = (props) => {
    const { title, setOpenAddModal } = props

    const [value, setValue] = useState<TRANSACTION_TYPE>()
    const [seeModalDisplayEditDelete, setSeeModalDisplayEditDelete] = useState(false)
    const [type, setType] = useState('')

    const { allTransactions } = useSelector((state: ROOT_REDUCER_TYPE) => state.transaction)

    const data: Array<TRANSACTION_TYPE> = allTransactions

    const handleDisplay = (type: string, value: TRANSACTION_TYPE) => {
        if (type === 'afficher') {
            setSeeModalDisplayEditDelete(true)
            setValue(value)
            setType('afficher')
        }
    }

    const columns = [
        { name: <h3>#</h3>, selector: (row: TRANSACTION_TYPE, i: number) => i + 1 },
        { name: <h3>Client ID</h3>, selector: (row: TRANSACTION_TYPE) => row.customerId, sortable: true },
        { name: <h3>Numéro de téléphone</h3>, selector: (row: TRANSACTION_TYPE) => row.phone ? row.phone : 'N/A', sortable: true },
        { name: <h3>Montant</h3>, selector: (row: TRANSACTION_TYPE) => row.amount, sortable: true },
        { name: <h3>Etat du paiement</h3>, selector: (row: TRANSACTION_TYPE) => row.paymentStatus === 'PENDING' ? <span className='column' style={{ color: '#d4a005' }}>En cours</span> : row.paymentStatus === 'CANCELED' ? <span className='column' style={{ color: '#EF3E34' }}>Annulé</span> : <span className='column' style={{ color: 'rgb(6, 161, 6)' }}>Validé</span> },
        { name: <h3>Dernière modification</h3>, selector: (row: TRANSACTION_TYPE) => displayDate((new Date(row?.updatedAt)).getTime()), sortable: true },
        {
            name: <h3 style={{ width: '100%', textAlign: 'center' }}>Action</h3>,
            cell: (row: TRANSACTION_TYPE) => (
                <div className='display_edit_delete' style={{ width: '100%', textAlign: 'center' }}>
                    <Popup arrow={false} trigger={<span className='vertical_icon_container'><BsThreeDotsVertical className='vertical_icon' /></span>} position='left center'>
                        <div className='display_edit_delete_container'>
                            <div className='container' onClick={() => handleDisplay('afficher', row)}>
                                <div className='container_icon'> <AiOutlineEye /> </div>
                                <div className='container_name'>Afficher</div>
                            </div>
                        </div>
                    </Popup>
                </div>
            )
        },
    ]

    return (
        <ListeContainer name='Liste des transactions'>
            <>
                <Liste title={title} columns={columns} data={data} setOpenAddModal={setOpenAddModal} />

                <TransactionModal type={type} row={value as TRANSACTION_TYPE} seeModalDisplayEditDelete={seeModalDisplayEditDelete} setSeeModalDisplayEditDelete={setSeeModalDisplayEditDelete} />
            </>
        </ListeContainer>
    )
}

export default ListeTransaction