import { toast } from 'react-toastify'
import { ADD_SETTING, DELETE_SETTING, EDIT_SETTING, ERROR_SETTING, GET_ALL_SETTINGS, LOADING_SETTING, setting } from '../constants'
import axios from 'axios'
import { SETTING_TYPE } from '../../utils/types'

const token = localStorage.getItem('token')

const _loadingSetting = () => (dispatch: any) => {
    dispatch({ type: LOADING_SETTING })
}

const _errorSetting = (payload: any) => (dispatch: any) => {
    dispatch({ type: ERROR_SETTING, payload })
}

export const _getAllSettings = () => async (dispatch: any) => {
    try {
        dispatch(_loadingSetting())

        const response = await axios.get(`${setting}`, { headers: { Authorization: `Bearer ${token}` } })

        dispatch({ type: GET_ALL_SETTINGS, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorSetting(error?.response?.data))
    }
}

export const _addSetting = (data: SETTING_TYPE, setAddData: React.Dispatch<React.SetStateAction<SETTING_TYPE>>) => async (dispatch: any) => {
    try {
        dispatch(_loadingSetting())

        const response = await axios.post(`${setting}`, data, { headers: { Authorization: `Bearer ${token}` } })

        toast.success('Le réglage a été ajouté avec succès.')

        setAddData({ name: '', value: '' })

        dispatch({ type: ADD_SETTING, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorSetting(error?.response?.data))
    }
}

export const _editSetting = (id: string, data: SETTING_TYPE, setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>) => async (dispatch: any) => {
    try {
        dispatch(_loadingSetting())

        const response = await axios.put(`${setting}/${id}`, data, { headers: { Authorization: `Bearer ${token}` } })

        toast.success('Le réglage a été modifié avec succès.')

        setSeeModalDisplayEditDelete(false)

        dispatch({ type: EDIT_SETTING, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorSetting(error?.response?.data))
    }
}

export const _deleteSetting = (id: string, setSeeModalDisplayEditDelete: React.Dispatch<React.SetStateAction<boolean>>) => async (dispatch: any) => {
    try {
        dispatch(_loadingSetting())

        const response = await axios.delete(`${setting}/${id}`, { headers: { Authorization: `Bearer ${token}` } })

        toast.success('Le réglage a été supprimé avec succès.')

        setSeeModalDisplayEditDelete(false)

        dispatch({ type: DELETE_SETTING, payload: response.data })
    } catch (error: any) {
        toast.error(error?.response?.data)
        dispatch(_errorSetting(error?.response?.data))
    }
}